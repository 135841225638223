import { Button, Dropdown, Menu, Modal, message } from "antd";
import { useContext } from "react";
import { FilterItem } from "models/types";
import { FilterChips } from "components";
import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import { SubscriptionContext } from "context/SubscriptionProvider";
import { useMutation } from "@apollo/client";
import {
  MUTATION_UPDATE_AUDIT_EXPORTED_LOGS,
  MUTATION_UPDATE_MATERIALS
} from "services/graphQL/mutations";
import DemoTextIcon from "components/svg-icons/demo-icon";
import { DownOutlined } from "@ant-design/icons";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import {
  AuditLogFeatures,
  AuditLogFeatureType,
  EFeaturePageId,
  ErrorMessages,
  InfoMessages,
  ProjectPermissionEnum,
  SuccessMessages
} from "../../../constants";
import ColumnConfigViews from "../../../components/column-config-views";

function MaterialListFilterBar(props: any) {
  const {
    gridRef,
    onExtractionClick,
    onNewClick,
    onImportClick,
    stats,
    onSearch,
    customDateFilter,
    items,
    setItems,
    setCustomDateFilter,
    showFiterChips,
    onAllMaterialsClick,
    onMaterialEditClick,
    onExportCSV,
    displayRowCount,
    resetFilters,
    selectedRows,
    setFilterStateFromData,
    afterApplyFilterCallBack,
    getColumnStateFromGrid,
    isGridDataRendered,
    onMaterialDeleted
  } = props;

  const { tokenContents, gqlClientForProject }: TProjectContext =
    useContext(ProjectContext);
  const { isFeatureFlagEnabled } = useContext(SubscriptionContext);
  const [updateMaterials] = useMutation<any>(MUTATION_UPDATE_MATERIALS, {
    client: gqlClientForProject
  });
  const [saveExportAuditLogEvent] = useCIQMutation(
    MUTATION_UPDATE_AUDIT_EXPORTED_LOGS,
    {
      client: gqlClientForProject
    }
  );

  const isMaterialExtractionEnabled = isFeatureFlagEnabled(
    "MATERIAL_EXTRACTION"
  );

  const selected = selectedRows ? selectedRows.length : 0;

  const onFilterChipDelete = (item: FilterItem) => {
    if (item.field === customDateFilter.field) {
      const index = items.findIndex(
        (val: any) => val.field === customDateFilter.field
      );
      if (index > -1) {
        delete items[index];
      }
      setItems([...items].filter(Boolean));
      setCustomDateFilter({});
      gridRef.current.api.destroyFilter(customDateFilter.field);
    } else {
      const filterInstance = gridRef.current.api.getFilterInstance(item.field);
      if (filterInstance) {
        const model = filterInstance.getModel();
        const values = model.values.filter((val: any) => val !== item.value);
        if (values.length === 0) {
          gridRef.current.api.destroyFilter(item.field);
          return;
        }
        filterInstance.setModel({ values });
        filterInstance.applyModel();
        gridRef.current.api.onFilterChanged();
      }
    }
  };

  const onCategoryDelete = (item: FilterItem) => {
    gridRef.current.api.destroyFilter(item.field);
  };

  const cannotCreateMaterial: any = isPermissionNotGrantted(
    ProjectPermissionEnum.CreateMaterial,
    tokenContents?.role!
  );

  const deleteMaterials = () => {
    Modal.confirm({
      title: InfoMessages.deleteMaterialConfirmTitle,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        try {
          const deleteMaterialsRes = await updateMaterials({
            variables: {
              set: { deleted: true },
              where: {
                id: { _in: selectedRows.map((s: any) => s.id) }
              }
            }
          });

          if (deleteMaterialsRes.data) {
            message.success(SuccessMessages.deleteMaterialSuccess);
            const ids = deleteMaterialsRes.data.update_material.returning.map(
              (x: { id: string }) => x.id
            );
            onMaterialDeleted(ids);
          }
          if (deleteMaterialsRes.errors) {
            console.error(deleteMaterialsRes.errors);
            message.error(ErrorMessages.deleteMaterialFailed);
          }
        } catch (ex: any) {
          console.log("exception in delete material ", ex);
          const errorMsg = ex.message || ErrorMessages.deleteMaterialFailed;
          message.error(errorMsg);
        }
      }
    });
  };

  const bulkEditActionMenuItems: any = (
    <Menu>
      <Menu.Item
        onClick={() => {
          onMaterialEditClick("details");
        }}
        key={0}
      >
        Bulk Edit Details
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          onMaterialEditClick("duration");
        }}
        key={1}
      >
        Bulk Edit Durations
      </Menu.Item>
    </Menu>
  );

  const saveExportAuditLog = async () => {
    const payload: any = {
      variables: {
        eventType: AuditLogFeatureType.LOG_EXPORTED,
        feature: AuditLogFeatures.MATERIAL_LOG
      }
    };
    await saveExportAuditLogEvent(payload);
  };

  return (
    <ActionBar>
      <div className="grow flex items-center">
        <div className="w-full flex space-x-10 uppercase">
          <span
            style={{
              fontWeight: 600
            }}
          >
            {stats}&nbsp;&nbsp;
            {selected > 0 && `${selected} Selected`}{" "}
          </span>
        </div>
        <div className="flex space-x-2 items-center">
          <div className="flex h-fit">
            <SearchInput
              placeholder="Search Materials"
              onChange={onSearch}
              disabled={isGridDataRendered}
            />
          </div>
          <ColumnConfigViews
            setFilterStateFromData={setFilterStateFromData}
            getColumnStateFromGrid={getColumnStateFromGrid}
            afterApplyFilterCallBack={afterApplyFilterCallBack}
            featureId={EFeaturePageId.MATERIAL}
          />
          {showFiterChips && (
            <FilterChips
              items={items}
              onChipDelete={onFilterChipDelete}
              onCategoryDelete={onCategoryDelete}
              resetAll={() => {
                resetFilters();
              }}
            />
          )}
          {!showFiterChips && (
            <Button onClick={() => onAllMaterialsClick()}>All Materials</Button>
          )}

          {selected > 1 && (
            <Dropdown
              overlay={bulkEditActionMenuItems}
              disabled={cannotCreateMaterial}
            >
              <Button
                disabled={cannotCreateMaterial}
                title={
                  cannotCreateMaterial
                    ? ErrorMessages.PermissionNotGranted
                    : "Bulk Edit"
                }
              >
                {`Edit (${selected})`}
                <DownOutlined />
              </Button>
            </Dropdown>
          )}

          {selected > 0 && (
            <Button
              onClick={deleteMaterials}
              size="middle"
              className="bottomBarBtn"
              title={
                cannotCreateMaterial
                  ? ErrorMessages.PermissionNotGranted
                  : "Delete"
              }
              disabled={cannotCreateMaterial}
            >
              Delete
            </Button>
          )}
          {isMaterialExtractionEnabled && !cannotCreateMaterial && (
            <Button onClick={onExtractionClick}>
              <div className="flex">
                <span className="-mt-[1px] mr-[2px]">
                  <DemoTextIcon />
                </span>{" "}
                Extract From Drawings
              </div>
            </Button>
          )}

          <Button
            onClick={onNewClick}
            disabled={cannotCreateMaterial}
            title={
              cannotCreateMaterial
                ? ErrorMessages.PermissionNotGranted
                : "New Material"
            }
            data-testid="new-material-button"
          >
            New Material
          </Button>
          <Button
            onClick={onImportClick}
            disabled={cannotCreateMaterial}
            title={
              cannotCreateMaterial ? ErrorMessages.PermissionNotGranted : ""
            }
            data-testid="import-button"
          >
            Import Log
          </Button>

          <Button
            size="middle"
            title="Export Material"
            disabled={!displayRowCount}
            onClick={() => {
              onExportCSV();
              saveExportAuditLog();
            }}
            data-testid="material-export-button"
          >
            Export
          </Button>
        </div>
      </div>
    </ActionBar>
  );
}

export default MaterialListFilterBar;
