import { gql } from "@apollo/client";

const MUTATION_INSERT_ONE_PROJECT = gql`
  mutation InsertOneProject($newProject: project_insert_input!) {
    insert_project_one(object: $newProject) {
      id
      name
    }
  }
`;

const MUTATION_UPDATE_PROJECT_BY_ID = gql`
  mutation UpdateProjectById(
    $projectId: uuid!
    $projectValues: project_set_input!
  ) {
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $projectValues) {
      id
      name
    }
  }
`;

const MUTATION_UPDATE_PROJECT_DEFAULT_OFFSET_PK = gql`
  mutation UpdateProjectOffsetPKMutation(
    $project_id: uuid!
    $set: project_offset_defaults_set_input!
  ) {
    update_project_offset_defaults_by_pk(
      pk_columns: { project_id: $project_id }
      _set: $set
    ) {
      project_id
    }
  }
`;

const MUTATION_UPDATE_NEW_PROJECT_DEFAULT_OFFSET_PK = gql`
  mutation UpdateNewProjectOffsetPKMutation(
    $feature_id: Int!
    $float: Int!
    $workflow_data: [workflowDataObject!]!
  ) {
    update_project_default_offsets(
      feature_id: $feature_id
      float: $float
      workflow_data: $workflow_data
    ) {
      message
    }
  }
`;

const MUTATION_PROJECT_FEATURE_CONFIG = gql`
  mutation updateFeatureConfigurationMutation(
    $feature_id: Int!
    $high_risk_threshold: Int!
    $low_risk_threshold: Int!
  ) {
    update_project_feature_configurations(
      where: { feature_id: { _eq: $feature_id } }
      _set: {
        high_risk_threshold: $high_risk_threshold
        low_risk_threshold: $low_risk_threshold
      }
    ) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_SUBMITTAL_BY_ID = gql`
  mutation SubmittalsPkMutation(
    $id: uuid!
    $submittalValues: submittals_set_input
  ) {
    update_submittals_by_pk(pk_columns: { id: $id }, _set: $submittalValues) {
      id
      title
    }
  }
`;

const MUTATION_INSERT_ATTACHMENT = gql`
  mutation InsertAttachmentMyMutation($attachment: attachments_insert_input!) {
    insert_attachments_one(object: $attachment) {
      id
    }
  }
`;

const MUTATION_INSERT_MARKUPS_ONE = gql`
  mutation InsertMarkupMutation(
    $layer_id: uuid!
    $type: String!
    $page_no: Int!
    $data: jsonb!
  ) {
    insert_markups_one(
      object: {
        layer_id: $layer_id
        page_no: $page_no
        type: $type
        data: $data
      }
    ) {
      id
    }
  }
`;

const MUTATION_UPDATE_MARKUPS = gql`
  mutation UpdateMarkupsMutation($id: uuid!, $set: markups_set_input!) {
    update_markups_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

const MUTATION_UPDATE_MARKUPS_MANY = gql`
  mutation MyMutation($where: markups_bool_exp!, $set: markups_set_input!) {
    update_markups_many(updates: { where: $where, _set: $set }) {
      affected_rows
    }
  }
`;

const MUTATION_INSERT_MARKUP_LAYER = gql`
  mutation InsertMarkupLayerMutation($attachment_id: uuid!) {
    insert_markup_layer_one(
      object: { attachment_id: $attachment_id, name: "First" }
    ) {
      id
    }
  }
`;

const MUTATION_INSERT_SUBMITTAL = gql`
  mutation InsertSubmittal($submittal: submittals_insert_input!) {
    insert_submittals_one(object: $submittal) {
      id
    }
  }
`;

const MUTATION_INSERT_SUMITTAL_LINKS = gql`
  mutation InsertSubmittalLinksMutation(
    $objects: [submittal_links_insert_input!]!
  ) {
    insert_submittal_links(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const MUTATION_DELETE_SUMITTAL_LINKS = gql`
  mutation deleteSubmittalLinksMutation($where: submittal_links_bool_exp!) {
    delete_submittal_links(where: $where) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_INSERT_SPECIFICATION = gql`
  mutation InsertSpecification($specification: specifications_insert_input!) {
    insert_specifications_one(object: $specification) {
      id
    }
  }
`;

const MUTATION_INSERT_USERS_TO_USER_SUBSCRIPTION = gql`
  mutation InsertProjectUsers($users: [userDataArray!]) {
    insert_subscription_users_multi(userData: $users) {
      message
    }
  }
`;

const MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION = gql`
  mutation InsertProjectUsers(
    $users: [userProjectDataArray]
    $projectId: String
    $triggerMail: Boolean = true
  ) {
    insert_project_users_multi(
      triggerMail: $triggerMail
      userData: $users
      projectId: $projectId
    ) {
      message
    }
  }
`;

const MUTATION_UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfileInfo($set: user_set_input, $where: user_bool_exp!) {
    update_user(_set: $set, where: $where) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_ATTACHMENT_BY_PK = gql`
  mutation UpdateAttachment($id: uuid!, $set: attachments_set_input!) {
    update_attachments_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

const MUTATION_UPDATE_SUBMITTALS = gql`
  mutation UpdateSubmittals(
    $where: submittals_bool_exp!
    $set: submittals_set_input
  ) {
    update_submittals(where: $where, _set: $set) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_UPDATE_MATERIALS = gql`
  mutation UpdateMaterials(
    $where: material_bool_exp!
    $set: material_set_input!
  ) {
    update_material(where: $where, _set: $set) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_INSERT_COMPANY = gql`
  mutation insertCompany($object: subscription_vendors_insert_input!) {
    insert_subscription_vendors_one(object: $object) {
      id
    }
  }
`;

const MUTATION_UPDATE_COMPANY = gql`
  mutation updateCompany(
    $_set: subscription_vendors_set_input!
    $pk_columns: subscription_vendors_pk_columns_input!
  ) {
    update_subscription_vendors_by_pk(pk_columns: $pk_columns, _set: $_set) {
      address
      id
      name
    }
  }
`;

const MUTATION_INSERT_COMPANY_TO_PROJECT = gql`
  mutation insertCompanyToProject(
    $companyData: [companyDataObject]
    $projectId: String
  ) {
    insert_project_vendor(projectId: $projectId, companyData: $companyData) {
      message
    }
  }
`;

const MUTATION_ACCEPT_PROJECT_INVITE = gql`
  mutation AcceptProjectInvite {
    update_project_users(where: {}) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_PROJECT_VENDOR = gql`
  mutation MyMutation(
    $where: project_vendors_bool_exp = {}
    $_set: project_vendors_set_input = {}
  ) {
    update_project_vendors(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_COMPANY_POC = gql`
  mutation MyMutation(
    $pk_columns: trade_partner_poc_pk_columns_input!
    $_set: trade_partner_poc_set_input
  ) {
    update_trade_partner_poc_by_pk(_set: $_set, pk_columns: $pk_columns) {
      first_name
      last_name
      phone
      deleted
    }
  }
`;

const MUTATION_INSERT_POC_TO_COMPANY = gql`
  mutation MyMutation($objects: [trade_partner_poc_insert_input]!) {
    insert_trade_partner_poc(objects: $objects) {
      returning {
        id
        email
        first_name
        last_name
        phone
      }
    }
  }
`;

const MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE = gql`
  mutation MyMutation(
    $user_id: String!
    $permission_id: Int
    $invited_user_company_id: String!
  ) {
    resend_email_invite(
      invited_user_id: $user_id
      invited_user_subscription_permission_id: $permission_id
      invited_user_company_id: $invited_user_company_id
    ) {
      message
    }
  }
`;

const MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE = gql`
  mutation MyMutation(
    $user_id: String!
    $role_id: Int
    $invited_user_company_id: String!
  ) {
    resend_email_invite(
      invited_user_id: $user_id
      invited_user_project_role_id: $role_id
      invited_user_company_id: $invited_user_company_id
    ) {
      message
    }
  }
`;

const MUTATION_RESEND_EMAIL_INVITE = gql`
  mutation MyMutation($user_id: String!, $invited_user_company_id: String!) {
    resend_email_invite(
      invited_user_id: $user_id
      invited_user_company_id: $invited_user_company_id
    ) {
      message
    }
  }
`;
const MUTATION_CANCEL_INVITATION = gql`
  mutation MyMutation($user_id: String!) {
    cancel_email_invite(invited_user_id: $user_id) {
      message
    }
  }
`;

const MUTATION_CANCEL_SUBSCRIPTION_INVITATION = gql`
  mutation MyMutation($user_id: String!) {
    cancel_subscription_invite(invited_user_id: $user_id) {
      message
    }
  }
`;

// const MUTATION_UPDATE_SUBMITTAL_BY_ID = gql`
//   mutation SubmittalsPkMutation(
//     $id: uuid!
//     $submittalValues: submittals_set_input
//   ) {
//     update_submittals_by_pk(pk_columns: { id: $id }, _set: $submittalValues) {
//       id
//       title
//     }
//   }
// `;

const MUTATION_UPDATE_SUBMITTAL_ONE = gql`
  mutation UpdateSubmittalOne(
    $submittal_id: String!
    $assignee: String
    $assign_to: String
    $comment: String
    $description: String
    $design_reviewer: String
    $gc_reviewer: String
    $due_date: String
    $issue_date: String
    $responsible_contractor: String
    $ROJ: String
    $spec_section_id: String
    $status: Int
    $submitter: String
    $type: Int
  ) {
    update_submittal_one(
      submittal_id: $submittal_id
      assignee: $assignee
      assign_to: $assign_to
      comment: $comment
      description: $description
      design_reviewer: $design_reviewer
      due_date: $due_date
      gc_reviewer: $gc_reviewer
      issue_date: $issue_date
      responsible_contractor: $responsible_contractor
      ROJ: $ROJ
      spec_section_id: $spec_section_id
      status: $status
      submitter: $submitter
      type: $type
    ) {
      message
    }
  }
`;

const MUTATION_CREATE_PROJECT_CALENDAR = gql`
  mutation MyMutation($objects: [gantt_calendar_insert_input!]!) {
    insert_gantt_calendar(objects: $objects) {
      returning {
        id
        work_shifts {
          id
          name
          hours
        }
      }
    }
  }
`;

const MUTATION_INSERT_SHIFT_TO_CALENDAR = gql`
  mutation MyMutation($objects: [calendar_shift_mapping_insert_input!]!) {
    insert_calendar_shift_mapping(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_UPDATE_PROJECT_CALENDAR = gql`
  mutation UpdateGanttCalendars(
    $where: gantt_calendar_bool_exp!
    $_set: gantt_calendar_set_input
  ) {
    update_gantt_calendar(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_PROJECT_CALENDAR_WEEKDAYS = gql`
  mutation InsertCalendarShiftMapping(
    $objects: [calendar_shift_mapping_insert_input!]!
  ) {
    insert_calendar_shift_mapping(
      objects: $objects
      on_conflict: {
        constraint: calendar_shift_mapping_calendar_id_work_day_shift_id_key
        update_columns: deleted
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_UPDATE_PROJECT_HOLIDAY_CALENDAR = gql`
  mutation UpdateProjectCalendar(
    $where: calendar_holiday_list_bool_exp!
    $_set: calendar_holiday_list_set_input
  ) {
    update_calendar_holiday_list(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

const MUTATION_INSERT_PROJECT_HOLIDAY_TO_EXISTING_CALENDAR = gql`
  mutation MyMutation($object: [calendar_holiday_list_insert_input!]!) {
    insert_calendar_holiday_list(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_INSERT_SPEC_SECTION = gql`
  mutation InsertSpecSection($object: spec_sections_insert_input!) {
    insert_spec_sections_one(object: $object) {
      id
    }
  }
`;

const MUTATION_INSERT_LINKING_SUBMITTAL_SCHEDULE = gql`
  mutation InsertSubmittalScheduleLink(
    $object: submittal_schedule_links_insert_input!
  ) {
    insert_submittal_schedule_links_one(
      object: $object
      on_conflict: {
        constraint: submittal_schedule_links_task_id_submittal_id_key
        update_columns: []
      }
    ) {
      task_id
      submittal_id
      created_by
    }
  }
`;

const MUTATION_INSERT_LINKING_SUBMITTAL_MATERIAL = gql`
  mutation MyMutation($objects: [submittal_material_link_insert_input!]!) {
    insert_submittal_material_link(
      objects: $objects
      on_conflict: {
        constraint: submittal_material_link_submittal_id_material_id_key
        update_columns: []
      }
    ) {
      affected_rows
      returning {
        material_id
        submittal_id
      }
    }
  }
`;

const MUTATION_INSERT_LINKING_MATERIAL_SCHEDULE = gql`
  mutation MyMutation($object: material_schedule_link_insert_input!) {
    insert_material_schedule_link_one(
      object: $object
      on_conflict: {
        constraint: material_schedule_link_material_id_task_id_key
        update_columns: []
      }
    ) {
      material_id
      task_id
    }
  }
`;

const MUTATION_DELETE_LINKING_SUBMITTAL_SCHEDULE = gql`
  mutation MyMutation($where: submittal_schedule_links_bool_exp!) {
    delete_submittal_schedule_links(where: $where) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
const MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL = gql`
  mutation MyMutation($where: submittal_material_link_bool_exp!) {
    delete_submittal_material_link(where: $where) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_DELETE_LINKING_MATERIAL_SCHEDULE = gql`
  mutation MyMutation($where: material_schedule_link_bool_exp!) {
    delete_material_schedule_link(where: $where) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_UPDATE_SUBMITTAL_DATEBLOCK = gql`
  mutation MyMutation(
    $submittal_id: uuid!
    $set: submittal_date_block_set_input!
  ) {
    update_submittal_date_block_by_pk(
      pk_columns: { submittal_id: $submittal_id }
      _set: $set
    ) {
      submittal_id
    }
  }
`;

const MUTATION_UPDATE_MATERIAL_DATEBLOCK = gql`
  mutation MyMutation(
    $material_id: uuid!
    $set: material_date_block_set_input!
  ) {
    update_material_date_block_by_pk(
      pk_columns: { material_id: $material_id }
      _set: $set
    ) {
      material_id
    }
  }
`;

const MUTATION_UPDATE_MATERIAL_MANY_DATEBLOCK = gql`
  mutation MyMutation(
    $set: material_date_block_set_input!
    $where: material_date_block_bool_exp!
  ) {
    update_material_date_block(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK = gql`
  mutation MyMutation(
    $set: date_block_set_input!
    $where: date_block_bool_exp!
  ) {
    update_date_block_many(updates: { where: $where, _set: $set }) {
      affected_rows
    }
  }
`;

const MUTATION_CREATE_DISTRIBUTION_GROUP = gql`
  mutation create_distribution($object: distribution_group_insert_input!) {
    insert_distribution_group_one(
      object: $object
      on_conflict: {
        constraint: distribution_group_project_id_name_key
        update_columns: deleted
      }
    ) {
      id
      name
    }
  }
`;

const MUTATION_INSERT_NEW_USER_TO_DISTRIBUTION_GROUP = gql`
  mutation addUsersToDL($users: [distribution_user_insert_input!]!) {
    insert_distribution_user(
      objects: $users
      on_conflict: {
        constraint: distribution_user_pkey
        update_columns: deleted
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_REMOVE_USER_FROM_DISTRIBUTION_GROUP = gql`
  mutation removUsersFromDL($where: distribution_user_bool_exp!) {
    update_distribution_user(_set: { deleted: true }, where: $where) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_UPDATE_DISTRIBUTION_GROUP = gql`
  mutation updateDLGroup(
    $_set: distribution_group_set_input!
    $where: distribution_group_bool_exp!
  ) {
    update_distribution_group(_set: $_set, where: $where) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const MUTATION_INSERT_SUBMITTAL_DISTRIBUTION_GROUP = gql`
  mutation insertDLSubmittal(
    $data: [submittal_distribution_list_insert_input!]!
  ) {
    insert_submittal_distribution_list(
      objects: $data
      on_conflict: {
        constraint: submittal_distribution_list_submittal_id_dl_id_key
        update_columns: deleted
      }
    ) {
      affected_rows
      returning {
        id
        submittal_id
      }
    }
  }
`;

const MUTATION_UPDATE_SUBMITTAL_DISTRIBUTION_GROUP = gql`
  mutation update_submittal_distribution_list(
    $where: submittal_distribution_list_bool_exp!
  ) {
    update_submittal_distribution_list(_set: { deleted: true }, where: $where) {
      affected_rows
      returning {
        id
        submittal_id
      }
    }
  }
`;

const MUTATION_INSERT_LOCATION = gql`
  mutation MyLocationMutation($object: location_insert_input!) {
    insert_location_one(object: $object) {
      name
      id
    }
  }
`;
const MUTATION_UPDATE_LOCATION = gql`
  mutation updateLocations(
    $_set: location_set_input
    $where: location_bool_exp!
  ) {
    update_location(_set: $_set, where: $where) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_MATERIAL_PK = gql`
  mutation UpdateMaterialByPk(
    $set: material_set_input
    $pk_columns: material_pk_columns_input!
  ) {
    update_material_by_pk(_set: $set, pk_columns: $pk_columns) {
      id
    }
  }
`;

const MUTATION_INSERT_MATERIAL = gql`
  mutation InsertMaterialOne($newMaterial: material_insert_input!) {
    insert_material_one(object: $newMaterial) {
      material_id
      id
    }
  }
`;

const MUTATION_UPDATE_SUBSCRIPTION_USER_PERMISSION = gql`
  mutation MyMutation(
    $where: subscription_users_bool_exp!
    $_set: subscription_users_set_input!
  ) {
    update_subscription_users(where: $where, _set: $_set) {
      affected_rows
      returning {
        user_id
        subscription_id
      }
    }
  }
`;

const MUTATION_UPDATE_SUBSCRIPTION_USER_NAME = gql`
  mutation MyMutation(
    $pk_columns: user_pk_columns_input!
    $_set: user_set_input
  ) {
    update_user_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;

const MUTATION_UPDATE_PROJECT_USER_PERMISSION = gql`
  mutation MyMutation($role_id: Int!, $user_id: String!) {
    update_users_project_role(role_id: $role_id, user_id: $user_id) {
      message
    }
  }
`;

const MUTATION_SUBMITTAL_SUMMARY = gql`
  mutation SubmittalSummary($submittalId: String!) {
    prepare_submittal_distribution_summary(submittalId: $submittalId) {
      alternateGcReviewers
      designReviewerCompanyName
      designReviewers
      distributorName
      emailSubject
      gcReviewers
      latestComment
      latestCommentUser
      latestResponse
      latestResponseUser
      mainGcReviewer
      projectName
      projectNum
      responsibleContractorCompanyName
      specSectionName
      specSectionNum
      submittalId
      submittalNumber
      submittalUrl
      submittalUserFriendlyId
      submitters
      toAddresses
      submittalDGDisplayList {
        name
        users {
          name
        }
      }
      submittalDLDisplayList {
        name
        type
      }
    }
  }
`;

const MUTATION_REDISTRIBUTE_SUBMITTAL = gql`
  mutation MyMutation($submittalId: String!, $toAddresses: [String]!) {
    redistribute_submittal(
      submittalId: $submittalId
      toAddresses: $toAddresses
    ) {
      message
    }
  }
`;

const MUTATION_UPDATE_EXTERNAL_ACCOUNT_DETAILS = gql`
  mutation UpdateExternalAccountAndProject(
    $ciqProjectId: uuid
    $externalCompanyId: String
    $externalProjectId: String
    $integrationKey: String
  ) {
    update_external_integrations(
      _append: {
        configuration: {
          external_company_id: $externalCompanyId
          external_project_id: $externalProjectId
        }
      }
      where: { project_id: { _eq: $ciqProjectId } }
      _set: { integration_key: $integrationKey }
    ) {
      affected_rows
    }
  }
`;

const MUTATION_PROJECT_DEFAULT_CONFIG = gql`
  mutation ProjectDefaultConfig(
    $feature_id: Int!
    $field_mapping: [fieldMappingObject!]
    $date_block_action: [dateBlockActionObject!]
  ) {
    update_project_feature_default_configurations(
      feature_id: $feature_id
      field_mapping: $field_mapping
      date_block_action: $date_block_action
    ) {
      message
    }
  }
`;

const MUTATION_SAVE_COLUMNS = gql`
  mutation MyMutation(
    $material_column_config: materialColumnConfigObject
    $submittal_column_config: submittalColumnConfigObject
    $bid_package_column_config: bidPackageColumnConfigObject
    $design_package_column_config: designPackageColumnConfigObject
  ) {
    update_user_column_configurations(
      material_column_config: $material_column_config
      submittal_column_config: $submittal_column_config
      bid_package_column_config: $bid_package_column_config
      design_package_column_config: $design_package_column_config
    ) {
      message
    }
  }
`;

const MUTATION_SAVE_VIEW_CONFIG = gql`
  mutation saveUserView(
    $name: String!
    $feature_id: Int!
    $column_config: jsonb!
  ) {
    insert_project_feature_user_view_one(
      object: {
        name: $name
        feature_id: $feature_id
        column_config: $column_config
      }
    ) {
      id
    }
  }
`;

const MUTATION_DELETE_VIEW_CONFIG = gql`
  mutation deleteUserViews($id: Int!) {
    delete_project_feature_user_view_by_pk(id: $id) {
      name
    }
  }
`;

const MUTATION_INSERT_COMMENT = gql`
  mutation InsertComment($object: comment_insert_input!) {
    insert_comment_one(object: $object) {
      id
    }
  }
`;

const INSERT_MATERIALS_MULTI_MUTATION = gql`
  mutation InsertMaterialsMutation($objects: [material_insert_input!]!) {
    insert_material(objects: $objects) {
      affected_rows
      returning {
        id
        material_id
      }
    }
  }
`;

const MUTATION_SELECT_MDB_TEMPLATE = gql`
  mutation SelectMDBTemplate(
    $where: date_block_bool_exp!
    $_set: date_block_set_input
  ) {
    update_date_block(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

const MUTATION_UPDATE_PROJECT_TEMPLATE_API = gql`
  mutation MyMutation(
    $template_id: String!
    $feature: String!
    $name: String!
    $milestones: [milestoneObjectUpdate]!
    $offsets: [offsetObjectUpdate]!
    $float: Int!
  ) {
    update_project_template(
      feature: $feature
      milestones: $milestones
      name: $name
      offsets: $offsets
      template_id: $template_id
      float: $float
    ) {
      message
    }
  }
`;

const MUTATION_UPDATE_SUBSCRIPTION_TEMPLATE_API = gql`
  mutation MyMutation(
    $template_id: String!
    $feature: String!
    $name: String!
    $milestones: [milestoneObjectUpdate]!
    $offsets: [offsetObjectUpdate]!
    $float: Int!
  ) {
    update_subscription_template(
      feature: $feature
      milestones: $milestones
      name: $name
      offsets: $offsets
      template_id: $template_id
      float: $float
    ) {
      message
    }
  }
`;

const MUTATION_SET_DEFAULT_MDB_TEMPLATE = gql`
  mutation SetDefaultMDBTemplate($materialTemplateId: uuid!) {
    update_project_workflow_templates(
      where: { id: { _eq: $materialTemplateId }, feature_id: { _eq: 2 } }
      _set: { default: true }
    ) {
      affected_rows
    }
  }
`;

const MUTATION_SET_DEFAULT_PROJECT_DB_TEMPLATE = gql`
  mutation SetDefaultProjectDBTemplate($templateId: uuid!, $feature_id: Int!) {
    update_project_workflow_templates(
      where: { id: { _eq: $templateId }, feature_id: { _eq: $feature_id } }
      _set: { default: true }
    ) {
      affected_rows
    }
  }
`;

const MUTATION_SET_DEFAULT_SUBSCRIPTION_TEMPLATE = gql`
  mutation SetDefaultSubscriptionMDBTemplate($materialTemplateId: uuid!) {
    update_subscription_workflow_templates(
      where: { id: { _eq: $materialTemplateId }, feature_id: { _eq: 2 } }
      _set: { default: true }
    ) {
      affected_rows
    }
  }
`;
const MUTATION_SET_DEFAULT_SUBSCRIPTION_WF_TEMPLATE = gql`
  mutation SetDefaultSubscriptionMDBTemplate(
    $templateId: uuid!
    $feature_id: Int!
  ) {
    update_subscription_workflow_templates(
      where: { id: { _eq: $templateId }, feature_id: { _eq: $feature_id } }
      _set: { default: true }
    ) {
      affected_rows
    }
  }
`;

const MUTATION_COPY_MDB_TEMPLATE = gql`
  mutation CopyMDBTemplate($name: String!, $materialTemplateId: String!) {
    copy_project_template(name: $name, template_id: $materialTemplateId) {
      template_id
      message
    }
  }
`;

const MUTATION_COPY_PROJECT_DB_TEMPLATE = gql`
  mutation CopyProjectDBTemplate($name: String!, $templateId: String!) {
    copy_project_template(name: $name, template_id: $templateId) {
      template_id
      message
    }
  }
`;

const MUTATION_COPY_SUBSCRIPTION_TEMPLATE = gql`
  mutation CopyTemplate($name: String!, $templateId: String!) {
    copy_subscription_template(name: $name, template_id: $templateId) {
      template_id
      message
    }
  }
`;

const MUTATION_UPDATE_SUBSCRIPTION_MDB_TEMPLATE = gql`
  mutation UpdateTemplate(
    $pk_columns: subscription_workflow_templates_pk_columns_input!
    $_set: subscription_workflow_templates_set_input!
  ) {
    update_subscription_workflow_templates_by_pk(
      pk_columns: $pk_columns
      _set: $_set
    ) {
      id
    }
  }
`;
const MUTATION_UPDATE_PROJECT_MDB_TEMPLATE = gql`
  mutation UpdateTemplate(
    $pk_columns: project_workflow_templates_pk_columns_input!
    $_set: project_workflow_templates_set_input!
  ) {
    update_project_workflow_templates_by_pk(
      pk_columns: $pk_columns
      _set: $_set
    ) {
      id
    }
  }
`;

const MUTATION_UPDATE_PROJECT_USER_ACTION = gql`
  mutation updateProjectUserAction($updates: [project_users_updates!]!) {
    update_project_users_many(updates: $updates) {
      affected_rows
    }
  }
`;

const UPDATE_PROJECT_FEATURE_DURATIONS = gql`
  mutation updateProjectFeatureDurations(
    $featureData: [UpdateFeatureDurationInput!]!
  ) {
    update_project_feature_durations(featureData: $featureData) {
      message
    }
  }
`;

const MUTATION_UPDATE_AUDIT_EXPORTED_LOGS = gql`
  mutation updateProjectFeatureDurations(
    $eventType: String!
    $feature: String!
  ) {
    audit_exported_logs(eventType: $eventType, feature: $feature) {
      message
    }
  }
`;

const MUTATION_DELETE_PROJECT = gql`
  mutation deleteProjects($projectIds: [uuid!]!) {
    update_project(
      _set: { deleted: true }
      where: { id: { _in: $projectIds } }
    ) {
      affected_rows
    }
  }
`;

export {
  MUTATION_INSERT_ONE_PROJECT,
  MUTATION_UPDATE_PROJECT_BY_ID,
  MUTATION_UPDATE_PROJECT_DEFAULT_OFFSET_PK,
  MUTATION_UPDATE_SUBMITTAL_BY_ID,
  MUTATION_INSERT_SUBMITTAL,
  MUTATION_INSERT_SPECIFICATION,
  MUTATION_INSERT_SUMITTAL_LINKS,
  MUTATION_DELETE_SUMITTAL_LINKS,
  MUTATION_INSERT_USERS_TO_USER_SUBSCRIPTION,
  MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION,
  MUTATION_UPDATE_USER_PROFILE,
  MUTATION_UPDATE_ATTACHMENT_BY_PK,
  MUTATION_INSERT_ATTACHMENT,
  MUTATION_UPDATE_SUBMITTALS,
  MUTATION_UPDATE_MATERIALS,
  MUTATION_INSERT_MARKUPS_ONE,
  MUTATION_INSERT_MARKUP_LAYER,
  MUTATION_UPDATE_MARKUPS,
  MUTATION_UPDATE_MARKUPS_MANY,
  MUTATION_INSERT_COMPANY,
  MUTATION_UPDATE_COMPANY,
  MUTATION_INSERT_COMPANY_TO_PROJECT,
  MUTATION_ACCEPT_PROJECT_INVITE,
  MUTATION_UPDATE_COMPANY_POC,
  MUTATION_INSERT_POC_TO_COMPANY,
  MUTATION_CANCEL_INVITATION,
  MUTATION_UPDATE_SUBMITTAL_ONE,
  MUTATION_RESEND_EMAIL_INVITE,
  MUTATION_CREATE_PROJECT_CALENDAR,
  MUTATION_INSERT_SPEC_SECTION,
  MUTATION_UPDATE_PROJECT_CALENDAR,
  MUTATION_UPDATE_PROJECT_HOLIDAY_CALENDAR,
  MUTATION_INSERT_PROJECT_HOLIDAY_TO_EXISTING_CALENDAR,
  MUTATION_CANCEL_SUBSCRIPTION_INVITATION,
  MUTATION_INSERT_LINKING_SUBMITTAL_SCHEDULE,
  MUTATION_INSERT_LINKING_SUBMITTAL_MATERIAL,
  MUTATION_INSERT_LINKING_MATERIAL_SCHEDULE,
  MUTATION_DELETE_LINKING_SUBMITTAL_SCHEDULE,
  MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL,
  MUTATION_DELETE_LINKING_MATERIAL_SCHEDULE,
  MUTATION_UPDATE_SUBMITTAL_DATEBLOCK,
  MUTATION_UPDATE_MATERIAL_DATEBLOCK,
  MUTATION_UPDATE_MATERIAL_MANY_DATEBLOCK,
  MUTATION_INSERT_SHIFT_TO_CALENDAR,
  MUTATION_CREATE_DISTRIBUTION_GROUP,
  MUTATION_INSERT_NEW_USER_TO_DISTRIBUTION_GROUP,
  MUTATION_REMOVE_USER_FROM_DISTRIBUTION_GROUP,
  MUTATION_UPDATE_DISTRIBUTION_GROUP,
  MUTATION_INSERT_SUBMITTAL_DISTRIBUTION_GROUP,
  MUTATION_UPDATE_SUBMITTAL_DISTRIBUTION_GROUP,
  MUTATION_INSERT_LOCATION,
  MUTATION_UPDATE_LOCATION,
  MUTATION_UPDATE_MATERIAL_PK,
  MUTATION_INSERT_MATERIAL,
  MUTATION_UPDATE_PROJECT_CALENDAR_WEEKDAYS,
  MUTATION_UPDATE_SUBSCRIPTION_USER_PERMISSION,
  MUTATION_UPDATE_SUBSCRIPTION_USER_NAME,
  MUTATION_UPDATE_PROJECT_USER_PERMISSION,
  MUTATION_UPDATE_PROJECT_VENDOR,
  MUTATION_REDISTRIBUTE_SUBMITTAL,
  MUTATION_SUBMITTAL_SUMMARY,
  MUTATION_UPDATE_EXTERNAL_ACCOUNT_DETAILS,
  MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK,
  MUTATION_UPDATE_NEW_PROJECT_DEFAULT_OFFSET_PK,
  MUTATION_PROJECT_FEATURE_CONFIG,
  MUTATION_PROJECT_DEFAULT_CONFIG,
  MUTATION_SAVE_COLUMNS,
  MUTATION_SAVE_VIEW_CONFIG,
  MUTATION_DELETE_VIEW_CONFIG,
  MUTATION_INSERT_COMMENT,
  INSERT_MATERIALS_MULTI_MUTATION,
  MUTATION_SELECT_MDB_TEMPLATE,
  MUTATION_SET_DEFAULT_MDB_TEMPLATE,
  MUTATION_SET_DEFAULT_SUBSCRIPTION_TEMPLATE,
  MUTATION_COPY_MDB_TEMPLATE,
  MUTATION_COPY_SUBSCRIPTION_TEMPLATE,
  MUTATION_UPDATE_PROJECT_TEMPLATE_API,
  MUTATION_UPDATE_SUBSCRIPTION_TEMPLATE_API,
  MUTATION_UPDATE_SUBSCRIPTION_MDB_TEMPLATE,
  MUTATION_UPDATE_PROJECT_MDB_TEMPLATE,
  MUTATION_UPDATE_PROJECT_USER_ACTION,
  UPDATE_PROJECT_FEATURE_DURATIONS,
  MUTATION_UPDATE_AUDIT_EXPORTED_LOGS,
  MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE,
  MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE,
  MUTATION_SET_DEFAULT_SUBSCRIPTION_WF_TEMPLATE,
  MUTATION_SET_DEFAULT_PROJECT_DB_TEMPLATE,
  MUTATION_COPY_PROJECT_DB_TEMPLATE,
  MUTATION_DELETE_PROJECT
};
