import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Tooltip,
  TreeSelect,
  message
} from "antd";
import DocumentListWidget from "components/document-list-widget";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import SpecNumberNameDropDown from "components/spec-number-name";
import { ProjectParticipants } from "hooks/project-participants";
import TextArea from "antd/lib/input/TextArea";
import { LinkOutlined } from "@ant-design/icons";
import { QUERY_MASTER_UNITS } from "services/graphQL/queries";
import { TMaterialItem } from "models/materials";
import ErrorBoundary from "components/error-boundary";
import MaterialLinks from "components/links/material-links/material-links";
import DateBlockDynamicUIPanel from "components/date-block/full-date-block";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import { Link } from "react-router-dom";
import { GoverningTaskType } from "components/date-block/models";
import { getFileDownloadURL } from "services/file-mgmt";
import { downloadFileFromS3andOpenInNewTab } from "utils/utils";
import { MUTATION_SELECT_MDB_TEMPLATE } from "services/graphQL/mutations";
import { GET_MDB_TEMPLATES } from "services/graphQL/ciq-queries";
import UserTag from "components/user-tag/user-tag";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import ActivityCard from "components/activity-card/activity-card";
import {
  AcceptanceStatus,
  ErrorMessages,
  ProjectPermissionEnum,
  SuccessMessages
} from "../../../constants";
import { UnitType, getTreeFromMeasuremntList } from "./units-tree";
import MaterialHistory from "../material-history";

const { Option } = Select;

type TMaterialDetailsTabProps = {
  materialDetailsState: TMaterialItem;
  materialDbData: TMaterialItem;
  setMaterialDetailsState: any;
  saveFieldChange: any;
  materialAttachments: any;
  commentsData:
    | undefined
    | {
        comment: Array<any>;
      };
  materialTitlePanel: JSX.Element;
  projectParticipants: ProjectParticipants;
  isGCUserLoggedIn?: boolean;
};

export default function MaterialDetailsTab(props: TMaterialDetailsTabProps) {
  const {
    materialDetailsState,
    materialDbData,
    setMaterialDetailsState,
    saveFieldChange,
    materialAttachments,
    materialTitlePanel,
    projectParticipants,
    commentsData,
    isGCUserLoggedIn
  } = props;

  const { projectId, materialId } = useParams() as any;

  const {
    gqlClientForProject,
    tokenContents,
    columnHeaders: { materialHeaderMap },
    projectDetails,
    eventLogs
  }: TProjectContext = useContext(ProjectContext);

  const materialGCRepresentativeUserId =
    materialDetailsState?.gc_representative || "";
  const [selectedGCRepresentative, setSelectedGCRepresentative] = useState(
    materialGCRepresentativeUserId
  );

  const [selectedMaterialTemplateId, setSelectedMaterialTemplateId] =
    useState("");

  const [applyingMDBTemplate, setApplyingMDBTemplate] = useState(false);

  const gcUsers = useMemo(() => {
    if (!isGCUserLoggedIn) {
      return [
        {
          status_id: materialDetailsState?.gc_representative_user?.status_id,
          id: materialDetailsState?.gc_representative_user?.id,
          first_name: materialDetailsState?.gc_representative_user?.first_name,
          last_name: materialDetailsState?.gc_representative_user?.last_name
        }
      ];
    }
    return projectParticipants?.gcReviewers || [];
  }, [
    projectParticipants?.gcReviewers,
    materialDetailsState,
    isGCUserLoggedIn
  ]);

  const canInlineEditMaterialGeneralInfoAndParticipants: any =
    !isPermissionNotGrantted(
      ProjectPermissionEnum.EditMaterialGeneralInfoAndParticipants,
      tokenContents?.role!
    );

  const canInlineEditMaterialCharacteristics: any = !isPermissionNotGrantted(
    ProjectPermissionEnum.EditMaterialCharacteristics,
    tokenContents?.role!
  );

  const cannotDeleteAttachment: any = isPermissionNotGrantted(
    ProjectPermissionEnum.DeleteMaterialAttachment,
    tokenContents?.role!
  );

  const canOpenLinkingPage = !isPermissionNotGrantted(
    ProjectPermissionEnum.ViewLinkingPage,
    tokenContents?.role!
  );

  const canEditLinkingPage = !isPermissionNotGrantted(
    ProjectPermissionEnum.EditLinkingPage,
    tokenContents?.role!
  );

  const cannotChangeMaterialTemplate = isPermissionNotGrantted(
    ProjectPermissionEnum.ChangeMaterialTemplate,
    tokenContents?.role!
  );

  const { data: allUnits } = useQuery<{
    material_quantity_unit: Array<UnitType>;
    material_size_unit: Array<UnitType>;
  }>(QUERY_MASTER_UNITS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const {
    data: MDBTemplates,
    loading,
    refetch: refetchMDB
  } = useCIQQuery<{
    project_workflow_templates: Array<any>;
  }>(GET_MDB_TEMPLATES, {
    client: gqlClientForProject
  });

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      if (
        eventLogs.some((x) => x.data_source === "project_workflow_templates")
      ) {
        refetchMDB(); // Refetching Material Template data
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, refetchMDB]);

  const [applyMDBTemplate] = useMutation(MUTATION_SELECT_MDB_TEMPLATE, {
    client: gqlClientForProject
  });

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("gc_representative", materialGCRepresentativeUserId);
  }, [gcUsers, form, materialGCRepresentativeUserId]);

  const warrantList = useMemo(
    () =>
      [...Array(21).keys()].map((v: number) => {
        if (v === 20) return { label: "Lifetime warranty", value: 100 };
        return {
          label: `${v + 1} years`,
          value: v + 1
        };
      }),
    []
  );

  const tradePartnerOptions = useMemo(() => {
    if (canInlineEditMaterialGeneralInfoAndParticipants) {
      return projectParticipants.materialTradePartners.map((company: any) => {
        return {
          id: company.vendor_id,
          name: company.subscription_vendor.name,
          companyInviteStatus: company?.project_vendors_company_status?.status,
          ...company
        };
      });
    }
    if (materialDetailsState?.materials_trade_partner) {
      const optionObj = {
        id: materialDetailsState?.materials_trade_partner.id,
        name: materialDetailsState?.materials_trade_partner.name,
        companyInviteStatus: ""
      };
      return [optionObj];
    }
    return [];
  }, [
    canInlineEditMaterialGeneralInfoAndParticipants,
    materialDetailsState?.materials_trade_partner,
    projectParticipants.materialTradePartners
  ]);

  const getAssigneeOptions = () => {
    if (canInlineEditMaterialGeneralInfoAndParticipants) {
      const asigneeUsers = projectParticipants.submitterUsers.filter(
        (user: any) => {
          return user.company.vendor_id === materialDetailsState?.trade_partner;
        }
      );

      return asigneeUsers;
    }

    if (materialDetailsState?.material_assignee) {
      const optionObj = {
        ...materialDetailsState?.material_assignee,
        company: {
          name: materialDetailsState?.material_assignee?.project_users[0]
            .subscription_vendor?.name
        }
      };
      return [optionObj];
    }
    return [];
  };

  const [linkedSubmittal, setLinkedSubmittal] = useState(
    materialDetailsState.submittal_material_links.length > 0
      ? materialDetailsState.submittal_material_links[0].submittal
      : undefined
  );

  useEffect(() => {
    const submittlIndex =
      materialDetailsState?.submittal_material_links.findIndex(
        (submittal: any) => submittal.submittal_id === linkedSubmittal?.id
      );
    if (submittlIndex > -1) {
      setLinkedSubmittal(
        materialDetailsState.submittal_material_links[submittlIndex].submittal
      );
    } else if (materialDetailsState.submittal_material_links.length > 0) {
      setLinkedSubmittal(
        materialDetailsState.submittal_material_links[0].submittal
      );
    } else {
      setLinkedSubmittal(undefined);
    }
  }, [linkedSubmittal?.id, materialDetailsState.submittal_material_links]);

  const materialWFStarted = useMemo(() => {
    return !!materialDetailsState?.date_block_materials[0]?.actual_milestone_1;
  }, [materialDetailsState?.date_block_materials]);

  const materialTemplateData = useMemo(() => {
    const selectedTemplateId =
      materialDetailsState?.date_block_materials[0]?.workflow_template_id;
    const data: {
      selectedTemplateId: string;
      selectedTemplateDetails: any;
      options: any;
    } = {
      selectedTemplateId,
      selectedTemplateDetails: null,
      options: []
    };
    if (!MDBTemplates?.project_workflow_templates) return data;
    MDBTemplates.project_workflow_templates
      .filter((template: any) => {
        return !template.disabled || template.id === selectedTemplateId;
      })
      .forEach(
        (template: {
          id: string;
          name: string;
          default: boolean;
          additional_offset: boolean;
        }) => {
          if (!data.selectedTemplateId && template.default) {
            data.selectedTemplateId = template.id;
          }
          if (data.selectedTemplateId === template.id) {
            data.selectedTemplateDetails = template;
          }
          let tooltip = template.name;
          if (materialWFStarted) {
            tooltip = ErrorMessages.cannotChangeMDBTemplateWFStarted;
          }
          if (cannotChangeMaterialTemplate) {
            tooltip = ErrorMessages.PermissionNotGranted;
          }

          data.options.push({
            label: template.name,
            value: template.id,
            title: tooltip
          });
        }
      );
    return data;
  }, [
    MDBTemplates,
    cannotChangeMaterialTemplate,
    materialDetailsState?.date_block_materials,
    materialWFStarted
  ]);

  useEffect(() => {
    if (materialTemplateData.selectedTemplateId) {
      setSelectedMaterialTemplateId(materialTemplateData.selectedTemplateId);
    }
  }, [materialTemplateData]);

  const materialAssigneeUserId =
    materialDetailsState.assignee || materialDetailsState.assignee_unregistered;

  const permissionsForActivityCard = useMemo(() => {
    const permissions = [];

    const canCreateComment = !isPermissionNotGrantted(
      ProjectPermissionEnum.CreateMaterialComment,
      tokenContents?.role!
    );

    const canCreateMaterialCommentIfRC =
      materialDetailsState?.trade_partner ===
      tokenContents?.claims["x-hasura-vendor-id"];

    if (canCreateMaterialCommentIfRC) {
      permissions.push("create_comment");
      return permissions;
    }

    if (canCreateComment) {
      permissions.push("create_comment");
      return permissions;
    }

    return [];
  }, [
    materialDetailsState?.trade_partner,
    tokenContents?.claims,
    tokenContents?.role
  ]);

  const materialGoverningActivity = useMemo(() => {
    const linkedTask = materialDetailsState?.material_schedule_links.find(
      (e: any) => e.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        isLinkedEndDate: linkedTask.linked_to_end_date
      } as GoverningTaskType;

    return {} as GoverningTaskType;
  }, [materialDetailsState?.material_schedule_links]);

  const submittalGoverningActivity = useMemo(() => {
    const linkedTask = linkedSubmittal?.submittal_schedule_links?.find(
      (task: any) => task.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        isLinkedEndDate: linkedTask.linked_to_end_date
      } as GoverningTaskType;

    return {} as GoverningTaskType;
  }, [linkedSubmittal?.submittal_schedule_links]);

  const onMaterialTemplateSelect = async (templateId: string) => {
    setSelectedMaterialTemplateId(templateId);
    setApplyingMDBTemplate(true);
    const payload = {
      variables: {
        where: { material_id: { _eq: materialId } },
        _set: { workflow_template_id: templateId }
      }
    };
    try {
      const applyMDBResponse = await applyMDBTemplate(payload);

      if (applyMDBResponse?.errors) {
        message.error(
          applyMDBResponse?.errors[0]?.message ||
            ErrorMessages.failedToApplyMDBTemplate
        );
        setSelectedMaterialTemplateId(materialTemplateData.selectedTemplateId);
      } else {
        message.success(SuccessMessages.DBTemplateAppliedSuccess);
      }
    } catch (ex: any) {
      console.log(ex);
      setSelectedMaterialTemplateId(materialTemplateData.selectedTemplateId);
      const errorMsg = ex.message || ErrorMessages.failedToApplyMDBTemplate;
      message.error(errorMsg);
    }
    setApplyingMDBTemplate(false);
  };

  const submittalDateBlockTitleDiv = useMemo(
    () => (
      <div className="flex items-center text-xs font-bold tracking-wider">
        SUBMITTAL:
        <Select
          className="pl-2"
          style={{
            minWidth: "330px",
            maxWidth: "330px"
          }}
          defaultValue={0}
          onChange={(index) => {
            setLinkedSubmittal(
              materialDetailsState.submittal_material_links[index].submittal
            );
          }}
          options={materialDetailsState.submittal_material_links.map(
            (l: any, index: number) => {
              return {
                label: l.submittal?.title,
                value: index
              };
            }
          )}
        />
      </div>
    ),
    [materialDetailsState.submittal_material_links]
  );

  const materialDateBlockTitleDiv = (
    <div className="w-[800px] flex space-x-12 tracking-wider h-8 items-center">
      <div className="text-xs font-bold">
        MATERIAL SCHEDULE{" "}
        {canOpenLinkingPage && (
          <Link
            to={`/project/${projectId}/schedule?tab=4&materialId=${materialDetailsState.id}`}
            title="Add Linking"
          >
            <LinkOutlined
              style={{
                color: "#1890ff",
                fontSize: 16,
                marginLeft: 10,
                marginTop: 2
              }}
            />
          </Link>
        )}
      </div>
      <div className="flex items-center pt-1">
        <div className="text-xs font-bold tracking-wider mr-1">
          MATERIAL TEMPLATE:
        </div>
        <div className="w-[300px] mr-4">
          <Select
            className="w-full"
            options={materialTemplateData.options}
            value={
              materialTemplateData.options.length
                ? selectedMaterialTemplateId
                : null
            }
            onChange={onMaterialTemplateSelect}
            disabled={
              cannotChangeMaterialTemplate ||
              materialWFStarted ||
              applyingMDBTemplate
            }
            loading={applyingMDBTemplate || loading}
            placeholder="Select a template"
          />
        </div>
      </div>
    </div>
  );

  const noLinkedSubmittalDBDiv = (
    <div className="no-linking-item-found">
      <div className="no-linking-item-found--title">No Submittals Linked</div>
      {canEditLinkingPage && (
        <Button
          type="link"
          disabled={!canEditLinkingPage}
          href={`/project/${projectId}/schedule?tab=4&materialId=${materialDetailsState.id}&linkSubmittal=true`}
        >
          Click here to link
        </Button>
      )}
    </div>
  );

  const historyTabForActivityCard = useMemo(() => {
    return (
      <div className="h-full max-h-full overflow-auto p-2">
        <ErrorBoundary>
          <MaterialHistory
            created_on={materialDetailsState?.created_on}
            created_by_user={materialDetailsState?.created_by_user}
          />
        </ErrorBoundary>
      </div>
    );
  }, [materialDetailsState?.created_by_user, materialDetailsState?.created_on]);

  const onDocumentClick = async (data: any) => {
    message.success(ErrorMessages.comments.fileDownloadStarted);
    try {
      const fileDownloadURLResp: any = await getFileDownloadURL([
        data.blob_key
      ]);
      await downloadFileFromS3andOpenInNewTab(
        fileDownloadURLResp.data.success.urls[data.blob_key],
        data.file_type
      );
    } catch (ex: any) {
      console.log("ex ", ex);
      const errorMsg: string =
        ex.response?.data?.error ||
        ex.message ||
        "Unable to download attachment";
      message.error(errorMsg);
    }
  };

  return (
    <div className="material-details mt-1 pb-8">
      <Form form={form} layout="vertical" className="space-y-4">
        <div className="grid grid-cols-12">
          <div className="col-span-12">{materialTitlePanel}</div>
          <div className="col-span-12 bg-white date-block-row">
            <ErrorBoundary>
              <div className="flex flex-col w-full justify-center p-6 overflow-x-auto">
                <div className="w-full flex space-x-2 justify-end pb-2 h-10">
                  <div className="pl-1 pr-6">* Governing date</div>
                  {/* <div>Date Block</div>
                    <Switch
                      onChange={(checked) => setShowImpactDateBlock(checked)}
                    />
                    <div>Risk View (Preview)</div> */}
                </div>
                <DateBlockDynamicUIPanel
                  submittal={linkedSubmittal}
                  material={materialDetailsState}
                  submittalTitle={submittalDateBlockTitleDiv}
                  materialTitle={materialDateBlockTitleDiv}
                  noSubmittalDateBlockDiv={noLinkedSubmittalDBDiv}
                  noMaterialDateBlockDiv={null}
                  governingTaskOfSubmittal={submittalGoverningActivity}
                  governingTaskOfMaterial={materialGoverningActivity}
                  componentLocation="MaterialDetail"
                />
              </div>
            </ErrorBoundary>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4 flex flex-col space-y-4">
            <Card className="card-block">
              <div className="uppercase text-xs font-bold tracking-wider pb-2">
                GENERAL INFORMATION
              </div>
              <div className="h-[380px]">
                <Form.Item
                  label={materialHeaderMap?.spec_section_no?.toUpperCase()}
                >
                  <SpecNumberNameDropDown
                    defaultSpecNumber={materialDetailsState?.spec_section_no}
                    defaultSpecName={materialDetailsState?.spec_section_name}
                    currentSpecSection={{
                      specSectionId: materialDetailsState?.spec_section_id,
                      enable: false
                    }}
                    disabled={!canInlineEditMaterialGeneralInfoAndParticipants}
                    onChange={(
                      id: string | null,
                      number: string | null,
                      name: string | null
                    ) => {
                      saveFieldChange({
                        spec_section_id: id,
                        spec_section_no: number,
                        spec_section_name: name
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item label={materialHeaderMap?.tag_name?.toUpperCase()}>
                  <Input
                    placeholder="Enter Material Tag"
                    value={materialDetailsState?.tag_name}
                    disabled={!canInlineEditMaterialGeneralInfoAndParticipants}
                    onChange={(event: any) => {
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          tag_name: event.target.value
                        };
                      });
                    }}
                    onBlur={async (event: any) => {
                      const value = event.target.value?.trim();
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          tag_name: value
                        };
                      });
                      const oldValue = materialDbData?.tag_name || "";
                      if (oldValue !== value)
                        saveFieldChange({
                          tag_name: event.target.value
                        });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={materialHeaderMap?.description?.toUpperCase()}
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter material description"
                    disabled={!canInlineEditMaterialGeneralInfoAndParticipants}
                    value={materialDetailsState?.description}
                    onChange={(event: any) => {
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          description: event.target.value
                        };
                      });
                    }}
                    onBlur={(event: any) => {
                      const value = event.target.value?.trim();
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          description: value
                        };
                      });
                      const oldValue = materialDbData?.description || "";
                      if (oldValue !== value)
                        saveFieldChange({ description: value });
                    }}
                  />
                </Form.Item>
              </div>
            </Card>
            <Card className="card-block">
              <div className="uppercase text-xs font-bold tracking-wider pb-2">
                PARTICIPANTS
              </div>
              <div className="h-[250px]">
                <Form.Item
                  label={materialHeaderMap?.trade_partner?.toUpperCase()}
                >
                  <Select
                    disabled={!canInlineEditMaterialGeneralInfoAndParticipants}
                    value={materialDetailsState?.trade_partner}
                    showSearch
                    filterOption
                    optionFilterProp="label"
                    onChange={async (value: string) => {
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          trade_partner: value,
                          assignee_unregistered: ""
                        };
                      });
                      saveFieldChange({
                        trade_partner: value,
                        assignee_unregistered: ""
                      });
                    }}
                    notFoundContent={
                      <SelectSearchNotFoundContent
                        notFoundMsg={
                          tradePartnerOptions?.length > 0
                            ? "Company not found. To add, go to "
                            : "No companies added to this project. To add, go to "
                        }
                        linkTitle="Project Companies"
                        linkPath={`/project/${projectId}/settings/general/project-companies`}
                      />
                    }
                  >
                    {tradePartnerOptions?.map((company: any) => {
                      return (
                        <Option
                          key={company.id}
                          value={company.id}
                          label={company.name}
                        >
                          {company.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Tooltip
                  title={
                    !materialDetailsState?.trade_partner
                      ? "First select a Responsible Contractor"
                      : ""
                  }
                  placement="rightBottom"
                >
                  <Form.Item label={materialHeaderMap?.assignee?.toUpperCase()}>
                    <Select
                      disabled={
                        !canInlineEditMaterialGeneralInfoAndParticipants ||
                        !materialDetailsState?.trade_partner
                      }
                      value={materialAssigneeUserId}
                      showSearch
                      filterOption
                      optionFilterProp="label"
                      onChange={async (value: string) => {
                        const selectedUser = getAssigneeOptions().find(
                          (user: any) => {
                            return user.id === value;
                          }
                        );

                        if (selectedUser.type === "actual") {
                          saveFieldChange({
                            assignee: value,
                            assignee_unregistered: ""
                          });
                          setMaterialDetailsState((prev: any) => {
                            return {
                              ...prev,
                              assignee: value,
                              assignee_unregistered: ""
                            };
                          });
                        } else {
                          saveFieldChange({
                            assignee: null,
                            assignee_unregistered: value
                          });
                          setMaterialDetailsState((prev: any) => {
                            return {
                              ...prev,
                              assignee: null,
                              assignee_unregistered: value
                            };
                          });
                        }
                      }}
                      notFoundContent="User not found. Go to Project Settings to add users"
                    >
                      {getAssigneeOptions().map((user: any) => {
                        if (
                          user.status_id === AcceptanceStatus.DEACTIVATED &&
                          user.id !== materialAssigneeUserId
                        )
                          return "";

                        const selectedAndInactive =
                          user.status_id === AcceptanceStatus.DEACTIVATED &&
                          user.id === materialAssigneeUserId;
                        return (
                          <Option
                            key={user.id}
                            value={user.id}
                            label={`${user.first_name} ${user.last_name}`}
                            className={selectedAndInactive ? "hidden" : ""}
                          >
                            {user.status_id ===
                              AcceptanceStatus.DEACTIVATED && (
                              <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
                                <UserTag label="Inactive" />
                              </div>
                            )}
                            <div>
                              {user.first_name} {user.last_name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {user.company.name}
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Tooltip>
                <Form.Item
                  name="gc_representative"
                  label={materialHeaderMap?.gc_representative?.toUpperCase()}
                >
                  <Select
                    disabled={!canInlineEditMaterialGeneralInfoAndParticipants}
                    value={selectedGCRepresentative}
                    showSearch
                    filterOption
                    optionFilterProp="label"
                    onChange={async (value: string) => {
                      saveFieldChange({
                        gc_representative: value
                      });
                      setSelectedGCRepresentative(value);
                    }}
                    notFoundContent="User not found. Go to Project Settings to add users"
                  >
                    {gcUsers.map((user: any) => {
                      if (
                        user.status_id === AcceptanceStatus.DEACTIVATED &&
                        user.id !== selectedGCRepresentative
                      )
                        return "";

                      const selectedAndInactive =
                        user.status_id === AcceptanceStatus.DEACTIVATED &&
                        user.id === selectedGCRepresentative;
                      return (
                        <Option
                          key={user.id}
                          value={user.id}
                          label={`${user.first_name} ${user.last_name}`}
                          className={selectedAndInactive ? "hidden" : ""}
                        >
                          {user.status_id === AcceptanceStatus.DEACTIVATED && (
                            <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
                              <UserTag label="Inactive" />
                            </div>
                          )}
                          <div>
                            {user.first_name} {user.last_name}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
            <Card className="card-block">
              <div className="uppercase text-xs font-bold tracking-wider pb-2">
                Material Characteristics
              </div>
              <div className="h-[380px]">
                <Form.Item
                  label={materialHeaderMap?.manufacturer?.toUpperCase()}
                >
                  <Input
                    placeholder="Enter Manufacturer"
                    disabled={!canInlineEditMaterialCharacteristics}
                    value={materialDetailsState?.manufacturer}
                    onChange={(event: any) => {
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          manufacturer: event.target.value
                        };
                      });
                    }}
                    onBlur={(event: any) => {
                      const value = event.target.value?.trim();
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          manufacturer: value
                        };
                      });
                      const oldValue = materialDbData?.manufacturer || "";
                      if (oldValue !== value)
                        saveFieldChange({ manufacturer: value });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={materialHeaderMap?.part_number?.toUpperCase()}
                >
                  <Input
                    placeholder="Enter part number"
                    value={materialDetailsState?.part_number}
                    disabled={!canInlineEditMaterialCharacteristics}
                    onChange={(event: any) => {
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          part_number: event.target.value
                        };
                      });
                    }}
                    onBlur={(event: any) => {
                      const value = event.target.value?.trim();
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          part_number: value
                        };
                      });
                      const oldValue = materialDbData?.part_number || "";
                      if (oldValue !== value)
                        saveFieldChange({ part_number: value });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={materialHeaderMap?.serial_number?.toUpperCase()}
                >
                  <Input
                    placeholder="Enter serial number"
                    value={materialDetailsState?.serial_number}
                    disabled={!canInlineEditMaterialCharacteristics}
                    onChange={(event: any) => {
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          serial_number: event.target.value
                        };
                      });
                    }}
                    onBlur={(event: any) => {
                      const value = event.target.value?.trim();
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          serial_number: value
                        };
                      });
                      const oldValue = materialDbData?.serial_number || "";
                      if (oldValue !== value)
                        saveFieldChange({ serial_number: value });
                    }}
                  />
                </Form.Item>
                <Form.Item label={materialHeaderMap?.warranty?.toUpperCase()}>
                  <Select
                    placeholder="Select warranty"
                    disabled={!canInlineEditMaterialCharacteristics}
                    value={materialDetailsState?.warranty}
                    options={warrantList}
                    onChange={(value: any) => {
                      setMaterialDetailsState((prev: any) => {
                        return {
                          ...prev,
                          warranty: value
                        };
                      });
                      saveFieldChange({ warranty: value });
                    }}
                  />
                </Form.Item>
                <div className="grid grid-cols-2 gap-x-2">
                  <Form.Item label={materialHeaderMap?.quantity?.toUpperCase()}>
                    <Input
                      type="number"
                      placeholder="Enter Quantity"
                      className="w-full"
                      disabled={!canInlineEditMaterialCharacteristics}
                      min={0}
                      value={materialDetailsState?.quantity}
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      onChange={(event: any) => {
                        const value = Number(
                          event.target.value.replace(/\D/g, "")
                        );
                        setMaterialDetailsState((prev: any) => {
                          return {
                            ...prev,
                            quantity: value
                          };
                        });
                      }}
                      onBlur={(event: any) => {
                        const value = Number(
                          event.target.value.replace(/\D/g, "")
                        );
                        setMaterialDetailsState((prev: any) => {
                          return {
                            ...prev,
                            quantity: value
                          };
                        });
                        const oldValue = materialDbData?.quantity || 0;
                        if (oldValue !== value)
                          saveFieldChange({
                            quantity: value
                          });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={materialHeaderMap?.quantity_unit_id?.toUpperCase()}
                  >
                    <TreeSelect
                      placeholder="Enter Quantity Unit"
                      disabled={!canInlineEditMaterialCharacteristics}
                      value={
                        materialDetailsState?.material_quantity_unit
                          ? `${materialDetailsState?.material_quantity_unit.measurement} ${materialDetailsState?.material_quantity_unit.unit}`
                          : undefined
                      }
                      treeData={
                        allUnits
                          ? getTreeFromMeasuremntList(
                              allUnits.material_quantity_unit
                            )
                          : []
                      }
                      onChange={(value: any) => {
                        const data = allUnits?.material_quantity_unit.find(
                          (x) => x.id === value
                        );
                        setMaterialDetailsState((prev: any) => {
                          return {
                            ...prev,
                            material_quantity_unit: {
                              id: data?.id,
                              unit: data?.unit,
                              measurement: data?.measurement
                            }
                          };
                        });
                        saveFieldChange({ quantity_unit_id: value });
                      }}
                      treeDefaultExpandAll
                    />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-2 gap-x-2">
                  <Form.Item
                    label={materialHeaderMap?.material_size?.toUpperCase()}
                  >
                    <Input
                      placeholder="Enter Material Size"
                      className="w-full"
                      disabled={!canInlineEditMaterialCharacteristics}
                      value={materialDetailsState?.material_size}
                      onChange={(event: any) => {
                        setMaterialDetailsState((prev: any) => {
                          return {
                            ...prev,
                            material_size: event.target.value
                          };
                        });
                      }}
                      onBlur={(event: any) => {
                        const value = event.target.value?.trim();
                        setMaterialDetailsState((prev: any) => {
                          return {
                            ...prev,
                            material_size: value
                          };
                        });
                        const oldValue = materialDbData?.material_size || "";
                        if (oldValue !== value)
                          saveFieldChange({
                            material_size: value
                          });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={materialHeaderMap?.size_unit_id?.toUpperCase()}
                  >
                    <TreeSelect
                      placeholder="Enter Material Size Unit"
                      disabled={!canInlineEditMaterialCharacteristics}
                      value={
                        materialDetailsState?.material_size_unit
                          ? `${materialDetailsState?.material_size_unit.measurement} ${materialDetailsState?.material_size_unit.unit}`
                          : undefined
                      }
                      treeData={
                        allUnits
                          ? getTreeFromMeasuremntList(
                              allUnits.material_size_unit
                            )
                          : []
                      }
                      onChange={(value: any) => {
                        const data = allUnits?.material_size_unit.find(
                          (x) => x.id === value
                        );
                        setMaterialDetailsState((prev: any) => {
                          return {
                            ...prev,
                            material_size_unit: {
                              id: data?.id,
                              unit: data?.unit,
                              measurement: data?.measurement
                            }
                          };
                        });
                        saveFieldChange({ size_unit_id: value });
                      }}
                      treeDefaultExpandAll
                    />
                  </Form.Item>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-span-4 flex flex-col space-y-4">
            <ActivityCard
              historyTabContents={historyTabForActivityCard}
              entityId={materialId}
              permissions={permissionsForActivityCard}
              dateBlock={materialDetailsState.date_block_materials[0]}
              commentsData={commentsData}
            />
          </div>
          <div className="col-span-4 flex flex-col space-y-4">
            <Card className="card-block links-card">
              <div className="uppercase text-xs font-bold tracking-wider pb-2">
                LINKS
              </div>
              <div className="h-[550px] overflow-auto">
                <ErrorBoundary>
                  <MaterialLinks
                    {...{
                      materialDetailsState,
                      canOpenLinkingPage,
                      canEditLinkingPage,
                      projectId,
                      projectDetails
                    }}
                  />
                </ErrorBoundary>
              </div>
            </Card>

            <Card className="card-block">
              <div className="flex w-full justify-between h-6">
                <div className="uppercase text-xs font-bold tracking-wider ">
                  ATTACHMENTS
                </div>
              </div>

              <div className="h-[550px] overflow-y-auto">
                {materialAttachments && materialAttachments.length > 0 ? (
                  <div>
                    <DocumentListWidget
                      documents={materialAttachments}
                      onDocumentClick={onDocumentClick}
                      showDeleteBtn={false}
                      onDeleteBtnClick={() => {}}
                      deleteDisabled={cannotDeleteAttachment}
                      source="material"
                    />
                  </div>
                ) : (
                  <div className="h-full max-h-full flex flex-col items-center justify-center">
                    <span className="text-xs text-neutral-400">
                      No attachments added.
                    </span>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </Form>
    </div>
  );
}
