import { SyncOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  DATE_FORMAT_MMDDYYYY_HHMM_A,
  DATE_FORMAT_MMDDYYYY_HHMM_A_Z,
  INPUT_DATE_FORMAT
} from "utils/dateutils";
import { useGetProjectINTGState } from "hooks/submittal";
import { invokeACCProjectSyncJob } from "services/integration-service";
import { ImportStatuses } from "utils/utils";
import { useParams } from "react-router";
import { EUserRoleName } from "../constants";

export function IntegrationProjectInfo(props: {
  syncBtnVisible: boolean;
  isFileBasedIntegration: boolean;
}) {
  const {
    tokenRetrievalState,
    tokenContents,
    gqlClientForProject,
    lastImportLogData,
    eventLogs
  }: TProjectContext = useContext(ProjectContext);

  const { syncBtnVisible, isFileBasedIntegration } = props;
  const { projectId } = useParams() as any;

  const isLoggedInUserGcAdmin =
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin] ||
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_sc_project_admin];

  const [invokingSync, setInvokingSync] = useState(false);
  const [projectInfoStr, setProjectInfoStr] = useState("");
  const [momentInstance, setMomentInstance] = useState<any>(null);

  const { data: projectIntegrationData } = useGetProjectINTGState({
    client: gqlClientForProject
  });

  const momentUpdateIntervalRef = useRef<any>(null);

  useEffect(() => {
    momentUpdateIntervalRef.current = setInterval(() => {
      if (moment().isUtcOffset()) {
        setMomentInstance(moment());
        clearInterval(momentUpdateIntervalRef.current);
        momentUpdateIntervalRef.current = null;
      }
    }, 1000);
  }, []);

  const lastImportStatus: any = useMemo(() => {
    if (!lastImportLogData) return null;
    return lastImportLogData;
  }, [lastImportLogData]);

  useEffect(() => {
    if (
      projectIntegrationData &&
      projectIntegrationData.project_integration_configs.length
    ) {
      const projConfigProperties =
        projectIntegrationData.project_integration_configs[0].integration_config
          .integration_config_properties;
      const foundProjConfigProp = projConfigProperties.find(
        (projConfigProp: any) => {
          return (
            projConfigProp.property_name === "AUTODESK_PROJECT_NAME" ||
            projConfigProp.property_name === "PROCORE_PROJECT_NAME"
          );
        }
      );

      const foundSubConfigForThisProject =
        projectIntegrationData.project_integration_configs[0].system_integration_config.subscription_integration_configs.find(
          (subscriptionConfig: any) => {
            return (
              subscriptionConfig.integration_config.id ===
              projectIntegrationData.project_integration_configs[0]
                .subscription_integration_config.config_id
            );
          }
        );

      const foundSubConfigProp =
        foundSubConfigForThisProject.integration_config.integration_config_properties.find(
          (subConfigProp: any) => {
            return (
              subConfigProp.property_name === "AUTODESK_ACCOUNT_NAME" ||
              subConfigProp.property_name === "PROCORE_COMPANY_NAME"
            );
          }
        );

      if (syncBtnVisible) {
        setProjectInfoStr(
          `${foundSubConfigProp?.property_value ?? ""} - ${
            foundProjConfigProp?.property_value ?? ""
          }`
        );
      }
    }
  }, [projectIntegrationData, syncBtnVisible]);

  const syncData = async () => {
    setInvokingSync(true);

    const projConfigId: string =
      projectIntegrationData.project_integration_configs[0].id;

    const invokeSyncResponse = await invokeACCProjectSyncJob(
      {
        project_config_id: projConfigId
      },
      tokenRetrievalState.token
    );
    if (invokeSyncResponse.success) {
      message.success("Sync started");
    }
    setInvokingSync(false);
  };

  const syncButtonTooltip = useMemo(() => {
    if (
      !momentInstance ||
      !lastImportStatus ||
      !lastImportStatus.lastSyncDateTime
    )
      return "";

    if (lastImportStatus.status === ImportStatuses.PROCESSING) {
      return "Sync is in progress";
    }
    if (
      lastImportStatus.status === ImportStatuses.PROCESSED ||
      lastImportStatus.status === ImportStatuses.FAILED
    ) {
      const nextAllowedSync = moment(
        lastImportStatus.lastSyncDateTime,
        INPUT_DATE_FORMAT
      ).add(5, "minutes");
      const allowSyncByTime = momentInstance.isSameOrAfter(nextAllowedSync);
      if (!allowSyncByTime)
        return (
          <div>
            <div>You can only sync again after</div>{" "}
            <div>{nextAllowedSync.format(DATE_FORMAT_MMDDYYYY_HHMM_A_Z)}</div>
          </div>
        );
    }
    return "Sync submittals";
  }, [lastImportStatus, momentInstance]);

  const isSyncButtonDisabled = useMemo(() => {
    if (!momentInstance || !lastImportStatus) return true;
    if (lastImportStatus.status === ImportStatuses.PROCESSING) return true;
    if (!lastImportStatus.lastSyncDateTime) return false;

    if (
      lastImportStatus.status === ImportStatuses.PROCESSED ||
      lastImportStatus.status === ImportStatuses.FAILED
    ) {
      const allowSyncByTime = momentInstance.isSameOrAfter(
        moment(lastImportStatus.lastSyncDateTime, INPUT_DATE_FORMAT).add(
          5,
          "minutes"
        )
      );
      return !allowSyncByTime;
    }
    return false;
  }, [lastImportStatus, momentInstance]);

  const syncStatusMessage = useMemo(() => {
    let statusString = "";

    // FILE Based Integration
    if (isFileBasedIntegration) {
      if (lastImportLogData?.fileData?.file_name) {
        statusString = `(From: ${
          lastImportLogData?.fileData?.file_name ?? ""
        })`;
      }
      if (lastImportStatus?.status === ImportStatuses.PROCESSING) {
        statusString += " | Import is in progress";
      }

      if (
        lastImportStatus?.status === ImportStatuses.PROCESSED ||
        lastImportStatus?.status === ImportStatuses.FAILED
      ) {
        statusString += ` | Last Import: ${
          lastImportStatus?.lastSyncDateTime
            ? moment(lastImportStatus?.lastSyncDateTime).format(
                DATE_FORMAT_MMDDYYYY_HHMM_A
              )
            : "NA"
        }`;
      }

      return statusString;
    }

    // API Based Integration
    if (projectInfoStr !== "") {
      statusString += `(From:${projectInfoStr})`;
    }

    if (
      lastImportStatus?.status === ImportStatuses.PROCESSED ||
      lastImportStatus?.status === ImportStatuses.FAILED
    ) {
      statusString += ` | Last synced: ${
        lastImportStatus?.lastSyncDateTime
          ? moment(lastImportStatus?.lastSyncDateTime).format(
              DATE_FORMAT_MMDDYYYY_HHMM_A
            )
          : "NA"
      }`;
    }

    if (lastImportStatus?.status === ImportStatuses.PROCESSING) {
      statusString += " | Sync is in progress";
    }

    return statusString;
  }, [
    isFileBasedIntegration,
    projectInfoStr,
    lastImportStatus,
    lastImportLogData?.fileData?.file_name
  ]);

  useEffect(() => {
    if (
      isFileBasedIntegration &&
      eventLogs.some(
        (log) =>
          log.project_id === projectId &&
          log.change_type === "update" &&
          log.data_source === "import_log" &&
          (log.info.import_type === "ACC_IMPORT" ||
            log.info.import_type === "INTEGRATION_FILE_IMPORT") &&
          log.info.status === "PROCESSED"
      )
    ) {
      message.success("Submittal Log imported successfully.");
    }

    if (
      isFileBasedIntegration &&
      eventLogs.some(
        (log) =>
          log.project_id === projectId &&
          log.change_type === "update" &&
          log.data_source === "import_log" &&
          (log.info.import_type === "ACC_IMPORT" ||
            log.info.import_type === "INTEGRATION_FILE_IMPORT") &&
          log.info.status === "FAILED"
      )
    ) {
      message.error("Submittal Log failed to import.", 20000);
    }
  }, [eventLogs, isFileBasedIntegration, projectId]);

  if ((!projectInfoStr || !momentInstance) && !isFileBasedIntegration)
    return null;

  return (
    <div className="flex items-center justify-start overflow-hidden px-6 space-x-1">
      <div
        title={syncStatusMessage}
        className="overflow-hidden truncate shrink"
      >
        {syncStatusMessage}
      </div>

      {isLoggedInUserGcAdmin && syncBtnVisible && (
        <div>
          <Tooltip
            title={syncButtonTooltip}
            destroyTooltipOnHide
            placement="right"
            popupVisible={!!syncButtonTooltip}
          >
            <Button
              disabled={invokingSync || isSyncButtonDisabled}
              size="small"
              icon={<SyncOutlined spin={invokingSync} />}
              onClick={syncData}
            />
          </Tooltip>
        </div>
      )}

      {isFileBasedIntegration && lastImportStatus?.status === "PROCESSING" && (
        <div>
          <Tooltip placement="right">
            <Button
              className="cursor-default"
              size="small"
              icon={<SyncOutlined spin />}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
}
